import Image from 'next/image';
import { Box, useMediaQuery } from '@chakra-ui/react';
import { HeroImageProps, src } from '@/src/components/UI/HeroImageProps';
import classNames from 'classnames';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import ConditionalFragment from '@/components/common/ConditionalFragment';
import { Property } from 'csstype';
import ObjectFit = Property.ObjectFit;

const Images: Partial<{ [key in src]: string }> = {
  Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/home_v_4.webp`,
  Sell: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/sell_v_2.webp`,
  aboutUS: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/about_v_5.webp`,
  jobs: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/career_v_2.webp`,
  agent: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/agent_v_3.webp`,
  abroad: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/abroad_v_2.webp`,
};
const LargeImages: Partial<{ [key in src]: string }> = {
  Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/home_v_4.webp`,
  Sell: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/desktop/hero/sell_v_2.webp`,
  aboutUS: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/about_v_5.webp`,
  abroad: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/abroad_v_2.webp`,
  agent: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/agent_v_3.webp`,
  jobs: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/largedesktop/hero/career_v_2.webp`,
};
const TabletImages: Partial<{ [key in src]: string }> = {
  // Home: `${process.env.NEXT_PUBLIC_GOOGLE_CDN_URL}/tablet/hero/home_v_2.webp`,
};
const HeroImage = ({ src, alt, action, mask = false, bannerSrc }: HeroImageProps) => {
  const [imageSize, setImageSize] = useState<{ naturalWidth: number; naturalHeight: number } | null>(null);
  const [innerWidth, setInnerWidth] = useState<number>(1024);
  const [url, setUrl] = useState<string>(src ? Images[src] ?? '' : '');

  const isHomepage = src === 'Home';

  const [isSM] = useMediaQuery('(min-width: 768px and max-width: 1023px)');
  const [isMD] = useMediaQuery('(min-width: 1024px)');

  const objectFit = useMemo<ObjectFit>(() => {
    let objectFit: ObjectFit = 'contain';
    if (imageSize) {
      if (imageSize.naturalWidth > imageSize.naturalHeight) {
        objectFit = 'cover';
      }
    }
    return objectFit;
  }, [imageSize]);

  useEffect(() => {
    if (!window) return;
    if (!src) return;
    if (!LargeImages[src]) return;
    const onResize = () => {
      let url = Images[src];

      if (window.innerWidth >= 1440) {
        url = LargeImages[src] ?? url;
      } else if (window.innerWidth >= 1024 && isHomepage) {
        url = Images[src] ?? url;
      } else if (window.innerWidth >= 768) {
        url = TabletImages[src] ?? url;
      }
      if (url) setUrl(url);
    };
    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  useLayoutEffect(() => {
    if (!window) return;
    setInnerWidth(window.innerWidth);
    const onResize = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return (
    <Box
      position='relative'
      className={classNames(
        // 'flex items-center justify-center',
        'w-full',
        'overflow-hidden',
        'h-[36.25rem] sm:h-[30rem] md:h-[40rem]'
        // {
        //   'h-[36.25rem] sm:h-[30rem] md:h-[40rem]': !isHomepage,
        // }
      )}
    >
      <ConditionalFragment condition={mask}>
        <div className={'relative w-full h-full bg-black z-10 bg-opacity-25'} />
      </ConditionalFragment>
      <div className={'absolute inset-0 bg-gray300'}>
        <Image
          priority
          src={bannerSrc ?? url}
          className={'bg-gray300'}
          width={innerWidth}
          height={isMD ? 640 : isSM ? 480 : 580}
          objectFit={objectFit}
          layout={'responsive'}
          alt={alt}
          onLoadingComplete={setImageSize}
        />
      </div>
      {action}
    </Box>
  );
};
export default HeroImage;
